import React from "react";
import ReactPlayer from "react-player";
import "../locales/index";
import { useTranslation } from "react-i18next";

const TemplateAds = () => {
  const { t } = useTranslation();
  const templates = [
    {
      id: 1,
      imgSrc:
        "https://v16-cc.capcut.com/6363ccce205f7180d9516847c67f57d9/67053498/video/tos/alisg/tos-alisg-ve-8fe9aq-sg/o8tbvBhqCFrUxQtj4jQfhllfIB3t2Br8Pgzcur/?a=348188&bti=NTo2NmY1NDM6&ch=0&cr=0&dr=0&lr=all&cd=0%7C0%7C0%7C0&cv=1&br=1834&bt=917&cs=0&ds=3&ft=GAAO2Inz7ThNz-xrXq8Zmo&mime_type=video_mp4&qs=0&rc=PGVmZGc6Z2Y8aTQ1NzVpZ0BpM2tkdzg6Zmo6aTMzOGVkNEAuMzVhMzAzNjMxYzNeYzJeYSNwX3FpcjRvMnJgLS1kYi1zcw%3D%3D&vvpl=1&l=021727875977942fdbddc5150000228df440619e000009cf861a7&btag=e00088000",
      alt: "Template 1",
    },
    {
      id: 2,
      imgSrc:
        "https://v16-cc.capcut.com/4585854b07775d0836cf225cc93cae77/67053499/video/tos/alisg/tos-alisg-ve-8fe9aq-sg/oETumsfB5Izi5IQCCo5gNNh3kHUdtHYBQBZf4B/?a=348188&bti=NTo2NmY1NDM6&ch=0&cr=0&dr=0&lr=all&cd=0%7C0%7C0%7C0&cv=1&br=2038&bt=1019&cs=0&ds=3&ft=GAAO2Inz7ThNz-xrXq8Zmo&mime_type=video_mp4&qs=0&rc=ZGRmNDZkZjw4aWU3NGU2M0BpM3M1bTs6ZnZ4ajMzOGVkNEBeYTBgMDAvNTIxXjRiNV9eYSNoYV9vcjRnLzBgLS1kYi1zcw%3D%3D&vvpl=1&l=021727875977942fdbddc5150000228df440619e000009cf861a7&btag=e00088000",
      alt: "Template 2",
    },
    {
      id: 3,
      imgSrc:
        "https://v16-cc.capcut.com/5edbd1c304e92fa3c4c533a5e5051554/67053494/video/tos/alisg/tos-alisg-ve-8fe9aq-sg/ocgFAQYonlwefFfnADb7KmUQjeOydQG6HBm2NL/?a=348188&bti=NTo2NmY1NDM6&ch=0&cr=0&dr=0&lr=all&cd=0%7C0%7C0%7C0&cv=1&br=1032&bt=516&cs=0&ds=3&ft=GAAO2Inz7ThNz-xrXq8Zmo&mime_type=video_mp4&qs=0&rc=Nzg4Zjk6OzozNTczNTRmNEBpam1lcDM6Zjl5ajMzOGVkNEA0MGNjXmNeXjExLy4xXl8wYSNtMF5ncjQwcy9gLS1kYi1zcw%3D%3D&vvpl=1&l=021727875977942fdbddc5150000228df440619e000009cf861a7&btag=e00088000",
      alt: "Template 3",
    },
    {
      id: 4,
      imgSrc:
        "https://v16-cc.capcut.com/38ff3d662f703d766017e0f63d0a8681/67053492/video/tos/alisg/tos-alisg-ve-8fe9aq-sg/oIyIo0BWOAmIOBfWBAtUQDCQBAXOw4toiIxzhy/?a=348188&bti=NTo2NmY1NDM6&ch=0&cr=0&dr=0&lr=all&cd=0%7C0%7C0%7C0&cv=1&br=2306&bt=1153&cs=0&ds=3&ft=GAAO2Inz7ThNz-xrXq8Zmo&mime_type=video_mp4&qs=0&rc=aGdpZmQ6ZzM7MzkzNTo0N0BpMzl0NzQ6Zjp2ajMzOGVkNEA0YDFjYi0tX18xLS0zXi0xYSNtby5fcjRfNS1gLS1kYi1zcw%3D%3D&vvpl=1&l=021727875977942fdbddc5150000228df440619e000009cf861a7&btag=e00088000",
      alt: "Template 4",
    },
    {
      id: 5,
      imgSrc:
        "https://v16-cc.capcut.com/ce9d69e9b550fd539720cf4501b6b1e8/67053499/video/tos/alisg/tos-alisg-ve-8fe9aq-sg/ocQOogBVtUvBCWnk6eosbUzbEnduLDEgQOyfBU/?a=348188&bti=NTo2NmY1NDM6&ch=0&cr=0&dr=0&lr=all&cd=0%7C0%7C0%7C0&cv=1&br=2372&bt=1186&cs=0&ds=3&ft=GAAO2Inz7ThNz-xrXq8Zmo&mime_type=video_mp4&qs=0&rc=NWQ0NzQ2O2RmMzYzO2Q3aEBpM2R4bWg6ZmxsajMzOGVkNEA0YGItLmExNWMxNDMyNTIvYSMuYmJycjRvZzBgLS1kYi1zcw%3D%3D&vvpl=1&l=021727875977942fdbddc5150000228df440619e000009cf861a7&btag=e00088000",
      alt: "Template 5",
    },
    {
      id: 6,
      imgSrc:
        "https://v16-cc.capcut.com/ec33a4c5ac98c1e23713532d2dfea614/67053496/video/tos/maliva/tos-maliva-v-be9c48-us/okGygne5nowFIONfHXegSPryXCbiICCQDNgwTD/?a=348188&bti=NTo2NmY1NDM6&ch=0&cr=0&dr=0&lr=all&cd=0%7C0%7C0%7C0&cv=1&br=1432&bt=716&cs=0&ds=3&ft=GAAO2Inz7ThNz-xrXq8Zmo&mime_type=video_mp4&qs=0&rc=Zzo5PDo1ODlnN2dlOjo3N0BpM2U3aDk6ZnZtajMzN2doM0A1YTAtYDUtNjQxYzFeMS8xYSNmazBycjRnczBgLS1kNjZzcw%3D%3D&vvpl=1&l=021727875977942fdbddc5150000228df440619e000009cf861a7&btag=e00088000",
      alt: "Template 6",
    },
    {
      id: 7,
      imgSrc:
        "https://v16-cc.capcut.com/b8f0ab27b6379588f6a8f95d3c21824a/67053497/video/tos/alisg/tos-alisg-ve-8fe9aq-sg/oonvW6vUxIoBXSAASA9f2TUhUHzQzTBaYwtEkt/?a=348188&bti=NTo2NmY1NDM6&ch=0&cr=0&dr=0&lr=all&cd=0%7C0%7C0%7C0&cv=1&br=1560&bt=780&cs=0&ds=3&ft=GAAO2Inz7ThNz-xrXq8Zmo&mime_type=video_mp4&qs=0&rc=ZjZkOzNpOGhmPGRkZDg6M0Bpajp3dTM6ZmZuaTMzOGVkNEBjLTReLzMyNjExMzY1Nl42YSNraTVtcjRfaGtgLS1kYi1zcw%3D%3D&vvpl=1&l=021727875977942fdbddc5150000228df440619e000009cf861a7&btag=e00088000",
      alt: "Template 7",
    },
  ];

  return (
    <div className=" text-center justify-center w-full mt-44 ">
      <div className="max-w-[1200px] text-[4rem] font-sans font-bold text-white leading-tight mx-auto">
        {t("templates")}
      </div>


      <div className="flex whitespace-nowrap justify-between mt-20 px-[150px]">
        {templates.map((template) => (
          <div className="rounded-lg flex-shrink-0">
            <ReactPlayer
              playing={true}
              loop={true}
              width={204}
              height={362}
              volume={1}
              muted={true}
              url={template.imgSrc}
              className="!w-[204px] !h-[362px] "
            />
          </div>
        ))}
      </div>

      <button className="bg-[#00cae0] px-4 py-2 rounded-md font-semibold font-sans text-[#000000]  mt-44" onClick={window.downloadExeFile}>
        {t("common.download")}
      </button>
    </div>
  );
};

export default TemplateAds;
