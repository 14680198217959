import ReactPlayer from "react-player";
import "../locales/index";
import { useTranslation } from "react-i18next";

function Intro() {
  const { t } = useTranslation();
  return (
    <div className="w-full h-screen relative">
      <ReactPlayer
        playing={true}
        loop={true}
        width="100%"
        height="auto"
        volume={1}
        muted={true}
        url="https://lf16-web-buz.capcut.com/obj/capcut-web-buz-us/common/videos/lv_landing_v3.webm"
        className="absolute top-0 right-0 left-0"
      />
      <div className="absolute inset-0 flex mt-44 justify-center">
        <div className="text-center">
          <h1 className="max-w-[1500px] text-6xl font-sans font-bold text-white leading-tight px-20">
            {t("intro.title")}
          </h1>
          <h2 className="mx-auto max-w-[1200px] text-lg font-sans font-semibold text-[#afafb5] mt-4 px-24">
            {t("intro.subtitle")}
          </h2>
          <div className="mt-8 flex flex-col items-center space-y-4">
            <button className="bg-[#00cae0] px-4 py-2 rounded-md font-semibold font-sans text-[#000000] w-[388px] h-[52px]" onClick={window.downloadExeFile}>
              {t("common.download")}
            </button>
            <button className="bg-[#65676d] px-4 py-2 rounded-md font-semibold font-sans text-white w-[388px] h-[52px]" onClick={window.downloadExeFile}>
              {t("intro.try-ai")}
            </button>
          </div>
          <div className="mt-16 flex flex-col items-center">
            <img
              src={require("../img/down.png")}
              className="h-[20px] w-[30px] "
            />

            <img
              src={require("../img/down.png")}
              className="h-[20px] w-[30px]"
            />
          </div>
          <h2 className="mx-auto max-w-[1200px] text-[1rem] font-sans font-normal text-[#afafb5] mt-4">
            {t("intro.explore-feature")}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Intro;
