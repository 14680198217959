import "../locales/index";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-black max-w-full h-fit pt-10">
      <div className="mx-auto w-[77%] flex flex-col content-center">
        <div className="flex flex-row justify-between py-20">
          <div className="flex flex-col gap-5">
            <img
              className="cursor-pointer"
              class="libs-pc-footer-logo__dark"
              width="170"
              height="32"
              src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/802672aa5aafee7a-509.png"
              alt="Capcut"
              loading="lazy"
            />
            <div className="text-footer-gray max-w-xs overflow-wrap break-words w-[65%] text-sm">
              {t("footer.text.information")}
            </div>
            <div>
              <button
                className="flex items-center text-white text-sm bg-footer-language py-2 px-3 rounded-lg flex-row gap-2 mt-4 hover:bg-footer-language/15"
                type="button"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 17"
                  preserveAspectRatio="xMidYMid meet"
                  fill="none"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  class="dSSCFJ25f6a4gytHfkYd"
                >
                  <g>
                    <path
                      data-follow-fill="currentColor"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8 1.834a6.667 6.667 0 1 1 0 13.333A6.667 6.667 0 0 1 8 1.834Zm0 12c.143 0 .632-.15 1.164-1.216a7.2 7.2 0 0 0 .522-1.45H6.313c.14.552.318 1.042.522 1.45.533 1.065 1.021 1.216 1.165 1.216Zm-1.926-4a11.823 11.823 0 0 1 0-2.667h3.851a11.823 11.823 0 0 1 0 2.667H6.074Zm4.982 1.333a8.682 8.682 0 0 1-.748 2.143 5.357 5.357 0 0 0 2.312-2.143h-1.564Zm2.109-1.333h-1.899a13.198 13.198 0 0 0 0-2.667h1.899a5.342 5.342 0 0 1 0 2.667Zm-8.432 0H2.834a5.343 5.343 0 0 1 0-2.667h1.899a13.218 13.218 0 0 0 0 2.667ZM3.38 11.167h1.564c.177.811.432 1.538.747 2.143a5.357 5.357 0 0 1-2.311-2.143Zm2.933-5.333h3.373a7.19 7.19 0 0 0-.522-1.451C8.632 3.318 8.143 3.167 8 3.167c-.144 0-.632.151-1.165 1.216a7.19 7.19 0 0 0-.522 1.451Zm4.743 0h1.564a5.357 5.357 0 0 0-2.312-2.143c.315.605.57 1.332.748 2.143ZM5.69 3.691c-.315.605-.57 1.332-.747 2.143H3.38a5.357 5.357 0 0 1 2.31-2.143Z"
                      fill-opacity=".7"
                      fill="currentColor"
                    ></path>
                  </g>
                </svg>
                <span>English</span>
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 12 12"
                  preserveAspectRatio="xMidYMid meet"
                  fill="none"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  class="Q0u1MhO0ZML4fYW9Ozf2"
                >
                  <g>
                    <path
                      stroke-width="2"
                      d="M9.898 4.157 6 8.055 2.102 4.157"
                      data-follow-stroke="#E6E6E6"
                      stroke="currentColor"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
            <div className="flex flex-row gap-4 mt-4">
              <a
                className="w-5 h-5"
                href="https://www.tiktok.com/@capcut"
                target="_self"
                rel="noreferrer"
              >
                <img
                  className="w-5 h-5"
                  class="socialLink-img__dark"
                  src="https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/bce2e125c32540fab05de66957848858~tplv-6rr7idwo9f-image.image"
                  alt="TikTok"
                />
              </a>
              <a
                className="w-5 h-5"
                href="https://www.youtube.com/c/CapCutofficial"
                target="_self"
                rel="noreferrer"
              >
                <img
                  className="w-5 h-5"
                  class="socialLink-img__dark"
                  src="https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/cec1f87f641b45e1b099915dc4c00d0b~tplv-6rr7idwo9f-image.image"
                  alt="YouTube"
                />
              </a>
              <a
                className="w-5 h-5"
                href="https://www.instagram.com/capcutapp/"
                target="_self"
                rel="noreferrer"
              >
                <img
                  className="w-5 h-5"
                  class="socialLink-img__dark"
                  src="https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/1cbde6b860b54ef395d800cc9f934661~tplv-6rr7idwo9f-image.image"
                  alt="Instagram"
                />
              </a>
              <a
                className="w-5 h-5"
                href="https://www.facebook.com/capcutapp/"
                target="_self"
                rel="noreferrer"
              >
                <img
                  className="w-5 h-5"
                  class="socialLink-img__dark"
                  src="https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/3a508777c115460bb1339e7e7b4688c3~tplv-6rr7idwo9f-image.image"
                  alt="Facebook"
                />
              </a>
            </div>
          </div>

          <div className="grid grid-cols-3 items-end gap-32 px-2">
            <div className="flex flex-col gap-4">
              <div className="text-white font-bold text-lg">
                {t("footer.text.tools")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.tools-1")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.tools-2")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.tools-3")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.tools-4")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.tools-5")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.tools-6")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.tools-7")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.tools-8")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.tools-9")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.tools-10")}
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="text-white font-bold text-lg">
                {t("footer.text.create")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.create-1")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.create-2")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.create-3")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.create-4")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.create-5")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.create-6")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.create-7")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.create-8")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.create-9")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.create-10")}
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="text-white font-bold text-lg">
                {t("footer.text.resource")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.resource-1")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.resource-2")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.resource-3")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.resource-4")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.resource-5")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.resource-6")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.resource-7")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.resource-8")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.resource-9")}
              </div>
              <div className="text-footer-gray text-sm cursor-pointer hover:text-[#00cae0]">
                {t("footer.text.resource-10")}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row mt-20 text-footer-gray text-12px justify-between py-6 border-gray-900 border-t-[1px]">
          <div className="flex flex-row gap-6 justify-between">
            <a href="/" className="cursor-pointer whitespace-nowrap">
              {t("footer.text.terms-services")}
            </a>
            <a href="/" className="cursor-pointer whitespace-nowrap">
              {t("footer.text.privacy-center")}
            </a>
            <a href="/" className="cursor-pointer whitespace-nowrap">
              {t("footer.text.privacy-policy")}
            </a>
            <a href="/" className="cursor-pointer whitespace-nowrap">
              {t("footer.text.cookie-policy")}
            </a>
            <a href="/" className="cursor-pointer whitespace-nowrap">
              {t("footer.text.license")}
            </a>
            <a href="/" className="cursor-pointer whitespace-nowrap">
              {t("footer.text.creator")}
            </a>
            <a href="/" className="cursor-pointer whitespace-nowrap">
              {t("footer.text.community")}
            </a>
          </div>

          <div className="flex flex-row gap-4 justify-between">
            <a href="/">{t("footer.text.explore")}</a>
            <a href="/" className="cursor-pointer whitespace-nowrap">
              {t("footer.text.sound")}
            </a>
            <a href="/" className="cursor-pointer whitespace-nowrap">
              {t("footer.text.shop")}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
