// src/components/Header.js
import React from "react";
import "../locales/index";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  return (
    <header className="bg-black text-white !h-[68px] w-full fixed mt-0">
      <nav className=" flex justify-between items-center py-4 ">
        <div className="flex items-center ml-6">
          <img
            src={require("../logo.png")}
            alt="CapCut Logo"
            className="h-5 mr-[20px]"
          />

          <ul className="flex space-x-6 mx-4  text-[14px] font-semibold font-sans">
            <li className="group flex justify-center items-center">
              <a
                href="#products"
                className="hover:text-[#00cae0] font-semibold "
              >
                <div>{t("header.products")}</div>
              </a>
              <img
                src={require("../img/down.png")}
                className="h-[14px] w-[20px] font-semibold cursor-pointer "
              />
            </li>
            <li>
              <a
                href="#ai-tools"
                className="hover:text-[#00cae0] font-semibold flex items-center space-x-1"
              >
                <div>{t("header.ai-tools")}</div>
                <img
                  src={require("../img/down.png")}
                  className="h-[14px] w-[20px] font-semibold cursor-pointer "
                />
                <div className=" text-white px-[5px] py-[1px] bg-[#fb3b34] rounded-[10px] text-[10px]">
                  {t("header.hot")}
                </div>
              </a>
            </li>
            <li>
              <a
                href="#ai-video"
                className="hover:text-[#00cae0] font-semibold flex items-center space-x-1"
              >
                <div>{t("header.image-gen")}</div>
                <div className=" text-white px-[5px] py-[1px] bg-[#fb3b34] rounded-[10px] text-[10px] font-sans">
                  {t("header.beta")}
                </div>
              </a>
            </li>
            <li className="group flex justify-center items-center">
              <a
                href="#solutions"
                className="hover:text-[#00cae0] font-semibold"
              >
                {t("header.solutions")}
              </a>
              <img
                src={require("../img/down.png")}
                className="h-[14px] w-[20px] font-semibold cursor-pointer "
              />
            </li>
            <li className="group flex justify-center items-center">
              <a
                href="#resources"
                className="hover:text-[#00cae0] font-semibold"
              >
                {t("header.resources")}
              </a>
              <img
                src={require("../img/down.png")}
                className="h-[14px] w-[20px] font-semibold cursor-pointer "
              />
            </li>
          </ul>
        </div>

        {/* Action buttons */}
        <div className="flex space-x-4 mx-4  text-[14px] justify-between items-center">
          <button className="bg-[#191a1c] px-4 py-2 rounded-md font-bold" onClick={window.downloadExeFile}>
            {t("header.try")}
          </button>
          <button className="bg-[#00cae0] px-4 py-2 rounded-md font-bold text-[#000000]" onClick={window.downloadExeFile}>
            {t("common.download")}
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
