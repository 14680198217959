import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Intro from "./components/Intro";
import BestTool from "./components/BestTool";
import TrustedBy from "./components/TrustedBy";
import TemplateAds from "./components/TemplateAds";
import SmartTool from "./components/SmartTool";
import RemoveBackground from "./components/RemoveBackground";
import ConvertVoiceToText from "./components/ConvertVoiceToText";
import CreativityToWork from "./components/CreativityToWork";
import SectionSignUp from './components/SectionSignUp';
import SectionLowerHalf from './components/SectionLowerHalf';
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Modal from "./components/Modal";

function App() {
    const {i18n} = useTranslation();

    useEffect(() => {
        const language = navigator.language || navigator.userLanguage;
        switch (language) {
            case "ru-RU":
                i18n.changeLanguage("ru");
                break;
            case "vi-VN":
                i18n.changeLanguage("vi");
                break;
            case "en-IN":
                i18n.changeLanguage("en");
                break;
            case "en-US":
                i18n.changeLanguage("en");
                break;
            case "zh-CN":
                i18n.changeLanguage("zh");
                break;
            default:
                i18n.changeLanguage("en");
        }
    }, []);

    return (
        <main className="bg-black min-h-screen flex flex-col overflow-hidden font-sans">
            <header className="fixed top-0 left-0 right-0 z-50 bg-black">
                <Header/>
            </header>
            <div className="pt-16">
                <div>
                    <Intro/>
                </div>
                <div className="mt-16">
                    <BestTool/>
                </div>
                <div className="mt-16">
                    <TrustedBy/>
                </div>
                <div>
                    <TemplateAds/>
                </div>
                <div>
                    <SmartTool/>
                </div>
                <div>
                    <RemoveBackground/>
                </div>
                <div>
                    <ConvertVoiceToText/>
                </div>
                <div>
                    <CreativityToWork/>
                </div>
            </div>
            <SectionLowerHalf/>
            <SectionSignUp/>
            <Footer/>
            <Modal/>
        </main>
    );
}

export default App;
