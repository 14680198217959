import React from "react";
import ReactPlayer from "react-player";
import "../locales/index";
import { useTranslation } from "react-i18next";

const RemoveBackground = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between items-center px-[200px] rounded-md gap-10 mt-36">
      {/* Left Side - Text Section */}
      <div className="text-white max-w-md font-sans pr-8 pb-40">
        <h1 className="text-5xl font-bold mb-4">{t("smart-tools.tool-1")}</h1>
        <p className="text-lg mb-6 text-[#949a9a]">
          {t("smart-tools.subtitle-1")}
        </p>
        <button className="bg-cyan-500 text-black px-6 py-3 rounded-lg font-bold hover:bg-cyan-600 transition duration-300" onClick={window.downloadExeFile}>
           {t("common.try-free")} →
        </button>
      </div>

      {/* Right Side - Image Section */}
      <div className="w-full h-[500px] rounded-md overflow-hidden">
        <ReactPlayer
          playing={true}
          loop={true}
          width="110%"
          height="80%"
          volume={1}
          muted={true}
          url="https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/4ac2c75058fe48b1b0f9375436a98416~tplv-6rr7idwo9f-image.image"
          className="top-0 right-0 left-0"
        />
      </div>
    </div>
  );
};

export default RemoveBackground;
