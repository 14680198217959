import React, { useState, useEffect } from "react";
import "../locales/index";
import { useTranslation } from "react-i18next";

const items = [
  {
    title: "section-lower-half.section-2.item-title-1",
    subtitle: "section-lower-half.section-2.item-subtitle-1",
    imageSrc:
      "//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/511195b5fd752d56-1920.jpg",
  },
  {
    title: "section-lower-half.section-2.item-title-2",
    subtitle: "section-lower-half.section-2.item-subtitle-2",
    imageSrc:
      "//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/e397452e31976c57-1920.jpg",
  },
  {
    title: "section-lower-half.section-2.item-title-3",
    subtitle: "section-lower-half.section-2.item-subtitle-3",
    imageSrc:
      "//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/2a145f0ad45a5a23-1920.jpg",
  },
];

const SectionAnimation = () => {
  const { t } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === items.length - 1 ? 0 : prevIndex + 1
        );
      }, 4000); // Chuyển ảnh sau mỗi 4 giây
      return () => clearInterval(interval);
    }
  }, [isHovered]);

  const currentItem = items[currentIndex];

  return (
    <section className="mx-auto max-w-full px-40 bg-[#0E0E11]">
      <h2 className="text-white font-bold text-5xl mb-5 text-center py-14">
        {t("section-lower-half.section-2.title")}
      </h2>
      <div className="flex flex-row justify-between items-center">
        <ul className="w-2/5 pl-14">
          {items.map((item, index) => (
            <li
              key={index}
              className={`mb-5 border-l-4 px-4 cursor-pointer transition-opacity duration-300 ${
                index === currentIndex ? "border-white" : "border-gray-500"
              } ${index === currentIndex ? "opacity-100" : "opacity-50"}`}
            >
              <p className="text-white font-bold">{t(item.title)}</p>
              <p className="text-section-subtitle-2 mt-2">{t(item.subtitle)}</p>
            </li>
          ))}
        </ul>

        <div
          className="w-[55%]"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img
            className="h-full w-full object-contain rounded-xl transition-transform duration-300"
            src={currentItem.imageSrc}
            width="600"
            height="500"
            alt="Dynamic content"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
};

export default SectionAnimation;
