import "../locales/index";
import { useTranslation } from "react-i18next";
import SectionAnimation from "./SectionAnimation";

const SectionLowerHalf = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="mx-auto max-w-full p-40 bg-[#0D0D0D]">
        <h2 className="text-white font-bold text-5xl mb-5 text-center py-14">
          {t("section-lower-half.section-1.title")}
        </h2>
        <div className="flex flex-col items-center space-y-12">
          <div className="flex flex-row space-x-8 w-full">
            <div className="relative w-1/2 h-350 rounded-xl border-gray-900 border overflow-hidden">
              <img
                className="object-cover w-full h-full rounded-xl"
                src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/a21e58e5b87387f3-1165.png"
                srcset="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/2770fe1396b282e7-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/ac6f42dac7c03096-640.png 640w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/62ed96a6e83a4a2c-960.png 960w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/a21e58e5b87387f3-1165.png 1165w"
                width="550"
                height="300"
                alt="Music"
                loading="lazy"
              />
              <div className="absolute top-8 left-8 text-white font-bold text-lg">
                {t("section-lower-half.section-1.music")}
                <div className="text-2xl font-bold pt-1">500,000+</div>
              </div>
              <div className="absolute bottom-8 left-8 text-white text-3xl font-bold">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 40 41"
                  preserveAspectRatio="xMidYMid meet"
                  fill="none"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  class="pc_landing_enrich_resources-itemList-item-icon"
                >
                  <g>
                    <path
                      d="M33.826 5.266A1 1 0 0 1 35 6.251V27.07h-.052a6.667 6.667 0 1 1-3.282-4.942V9.708L15 12.648v17.088h-.002a6.667 6.667 0 1 1-3.331-5.941V10.012a1 1 0 0 1 .826-.984l21.333-3.762Zm-2.16 22.637a3.333 3.333 0 1 1-6.666 0 3.333 3.333 0 0 1 6.667 0Zm-23.333 5a3.333 3.333 0 1 0 0-6.667 3.333 3.333 0 0 0 0 6.667Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      data-follow-fill="#E6E6E6"
                      fill="#E6E6E6"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className="relative w-1/2 h-[350px] rounded-xl border-gray-900 border overflow-hidden">
              <img
                className="object-cover w-full h-full rounded-xl"
                src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/7729beed5247bf75-1165.png"
                srcSet="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/52fddd2a924480fe-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/dae7a333b7a7de09-640.png 640w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/2539810d90a1f531-960.png 960w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/7729beed5247bf75-1165.png 1165w"
                width="550"
                height="300"
                alt="Stickers"
                loading="lazy"
              />
              <div className="absolute top-8 left-8 text-white font-bold text-lg">
                {t("section-lower-half.section-1.stickers")}
                <div className="text-2xl font-bold pt-1">4,600+</div>
              </div>
              <div className="absolute bottom-8 left-8 text-white text-3xl font-bold">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 40 41"
                  preserveAspectRatio="xMidYMid meet"
                  fill="none"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  class="pc_landing_enrich_resources-itemList-item-icon"
                >
                  <g>
                    <path
                      d="M36.667 20.236c0-.68-.041-1.35-.12-2.008a1.88 1.88 0 0 0-.548-1.1l-12.89-12.89a1.881 1.881 0 0 0-1.1-.549c-.66-.079-1.33-.12-2.009-.12-9.205 0-16.667 7.462-16.667 16.667 0 9.205 7.462 16.667 16.667 16.667 9.205 0 16.667-7.462 16.667-16.667ZM20 6.903c.373 0 .741.015 1.105.045l.038.038c.45 6.48 5.627 11.658 12.107 12.107l.038.038c.03.364.045.732.045 1.105 0 7.364-5.97 13.333-13.333 13.333-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.333 13.333-13.333Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      data-follow-fill="#E6E6E6"
                      fill="#E6E6E6"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <div className="flex flex-row space-x-8 w-full h-fit">
            <div className="relative overflow-hidden w-1/3 h-350 rounded-xl border-gray-900 border">
              <img
                className="object-cover w-full h-full rounded-xl"
                src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/e453b1a8064a8174-757.png"
                srcset="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/6a71944a981e32f7-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/565b71473a9190e0-640.png 640w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/e453b1a8064a8174-757.png 757w"
                width="400"
                height="350"
                alt="Texts"
                loading="lazy"
              />
              <div className="absolute top-8 left-8 text-white font-bold text-16px">
                {t("section-lower-half.section-1.texts")}
                <div className="text-2xl font-bold pt-1">1,300+</div>
              </div>
              <div className="absolute bottom-8 left-8 text-white text-3xl font-bold">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 40 41"
                  preserveAspectRatio="xMidYMid meet"
                  fill="none"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  class="pc_landing_enrich_resources-itemList-item-icon"
                >
                  <g>
                    <path
                      d="M5.5 6.903a.5.5 0 0 0-.5.5v2.334a.5.5 0 0 0 .5.5H18v24.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-24.5h12.5a.5.5 0 0 0 .5-.5V7.403a.5.5 0 0 0-.5-.5h-29Z"
                      data-follow-fill="#E6E6E6"
                      fill="#E6E6E6"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>

            <div className="relative overflow-hidden w-1/3 h-350 rounded-xl border-gray-900 border">
              <img
                className="object-cover w-full h-full rounded-xl"
                src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/c001c3114f3cd53c-757.png"
                srcset="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/72b6909287a43d09-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/221008ad3ec21590-640.png 640w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/c001c3114f3cd53c-757.png 757w"
                width="400"
                height="350"
                alt="Effects"
                loading="lazy"
              />
              <div className="absolute top-8 left-8 text-white font-bold text-16px">
                {t("section-lower-half.section-1.effects")}
                <div className="text-2xl font-bold pt-1">1,100+</div>
              </div>
              <div className="absolute bottom-8 left-8 text-white text-3xl font-bold">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 40 41"
                  preserveAspectRatio="xMidYMid meet"
                  fill="none"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  class="pc_landing_enrich_resources-itemList-item-icon"
                >
                  <g>
                    <path
                      d="m11.831 4.275 8.17 4.295 8.168-4.295c1.467-.771 3.182.475 2.902 2.109l-1.56 9.096 6.608 6.442c1.187 1.156.532 3.172-1.108 3.41l-7.059 1.027 6.505 8.632a.5.5 0 0 1-.098.7l-1.864 1.405a.5.5 0 0 1-.7-.099l-6.658-8.835-3.343 6.774c-.734 1.486-2.854 1.486-3.587 0l-4.085-8.276-9.133-1.327c-1.64-.238-2.295-2.255-1.108-3.411l6.609-6.442-1.56-9.096c-.28-1.634 1.434-2.88 2.901-2.109ZM25.93 16.643l5.93 5.78-8.195 1.19L20 31.038l-3.664-7.425-8.194-1.19 5.93-5.78-1.4-8.16L20 12.336l7.329-3.853-1.4 8.16Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      data-follow-fill="#E6E6E6"
                      fill="#E6E6E6"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className="relative overflow-hidden w-1/3 h-350 rounded-xl border-gray-900 border">
              <img
                className="object-cover w-full h-full rounded-xl"
                src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/8125180ced2bbad3-757.png"
                srcset="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/60f106a59ac3dc5a-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/25d3b196310c788d-640.png 640w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/8125180ced2bbad3-757.png 757w"
                width="400"
                height="350"
                alt="Filters"
                loading="lazy"
              />
              <div className="absolute top-8 left-8 text-white font-bold text-16px">
                {t("section-lower-half.section-1.filters")}
                <div className="text-2xl font-bold pt-1">200+</div>
              </div>
              <div className="absolute bottom-8 left-8 text-white text-3xl font-bold">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 40 41"
                  preserveAspectRatio="xMidYMid meet"
                  fill="none"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  class="pc_landing_enrich_resources-itemList-item-icon"
                >
                  <g>
                    <path
                      d="M29.3 17.253c.452-1.14.7-2.383.7-3.684 0-5.523-4.477-10-10-10s-10 4.477-10 10c0 1.301.248 2.544.7 3.684-4.245 1.156-7.367 5.038-7.367 9.65 0 5.523 4.478 10 10 10A9.962 9.962 0 0 0 20 34.356a9.962 9.962 0 0 0 6.666 2.547c5.523 0 10-4.477 10-10 0-4.612-3.121-8.494-7.366-9.65Zm-10.05 2.941a6.668 6.668 0 1 1 6.5-3.25 9.977 9.977 0 0 0-6.5 3.25Zm-6.666.084a6.668 6.668 0 1 0 6.478 3.212l-.015.035a9.977 9.977 0 0 1-6.463-3.247Zm7.415 1.625Zm13.334 5a6.667 6.667 0 0 1-11.248 4.842 9.954 9.954 0 0 0 1.248-4.842 9.954 9.954 0 0 0-1.248-4.843 6.667 6.667 0 0 1 11.249 4.843Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      data-follow-fill="#E6E6E6"
                      fill="#E6E6E6"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SectionAnimation />

      <section className="mx-auto max-w-full p-40 bg-[#0D0D0D]">
        <h2 className="text-white font-bold text-5xl mb-5 text-center py-10">
          {t("section-lower-half.section-3.title")}
        </h2>
        <div className="flex flex-row gap-8 text-center justify-center">
          <div className="flex flex-col bg-section-item-bg rounded-xl">
            <img
              className=""
              src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/bfd8f4ed82adb159-560.png"
              srcset="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/b05847c0d0790313-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/bfd8f4ed82adb159-560.png 560w"
              width="280"
              height="234"
              alt="Protect your privacy"
              loading="lazy"
            />
            <div className="w-72 p-8">
              <h3 className="text-white text-lg font-bold">
                {t("section-lower-half.section-3.item-title-1")}
              </h3>
              <p className="text-section-subtitle">
                {t("section-lower-half.section-3.item-subtitle-1")}
              </p>
            </div>
          </div>
          <div className="flex flex-col bg-section-item-bg rounded-xl">
            <img
              className=""
              src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/83d5debe69e938e5-560.png"
              srcset="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/fd71077127232001-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/83d5debe69e938e5-560.png 560w"
              width="280"
              height="234"
              alt="Safe data storage"
              loading="lazy"
            />
            <div className="w-72 p-8">
              <h3 className="text-white text-lg font-bold">
                {t("section-lower-half.section-3.item-title-2")}
              </h3>
              <p className="text-section-subtitle">
                {t("section-lower-half.section-3.item-subtitle-2")}
              </p>
            </div>
          </div>
          <div className="flex flex-col bg-section-item-bg rounded-xl">
            <img
              className=""
              src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/2ae86535d4f5296d-560.png"
              srcset="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/1205676e3232d618-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/2ae86535d4f5296d-560.png 560w"
              width="280"
              height="234"
              alt="Smoother access"
              loading="lazy"
            />
            <div className="w-72 p-8">
              <h3 className="text-white text-lg font-bold">
                {t("section-lower-half.section-3.item-title-3")}
              </h3>
              <p className="text-section-subtitle">
                {t("section-lower-half.section-3.item-subtitle-3")}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mx-auto max-w-full w-[85%] p-40 h-fit #0D0D0D">
        <h2 className="text-white font-bold text-5xl mb-5 text-center">
          {t("section-lower-half.section-4.title")}
        </h2>
        <p className="text-section-subtitle mb-20">
          {t("section-lower-half.section-4.subtitle")}
        </p>
        <div className="flex flex-col items-center space-y-8">
          <div className="w-full h-300 bg-section-item-bg rounded-xl p-5 flex flex-row justify-between">
            <img
              className="w-1/2"
              src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/ee4eb356e1c1959a-1112.png"
              srcset="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/a25465583e683903-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/02112a9ccd85a6e2-640.png 640w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/889878066e2801d1-960.png 960w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/ee4eb356e1c1959a-1112.png 1112w"
              width="420"
              height="320"
              alt="CapCut online creative suite"
              loading="lazy"
            />
            <div className="flex flex-col gap-1 w-[45%] my-auto">
              <h3 className="text-white font-semibold text-lg">
                {t("section-lower-half.section-4.item-title-1")}
              </h3>
              <p className="text-section-subtitle">
                {t("section-lower-half.section-4.item-subtitle-1")}
              </p>
              <a
                href="/"
                className="text-white bg-section-btn-bg w-fit rounded-md px-4 py-[10px] text-center flex justify-center mt-3"
              >
                <span className="font-bold text-13px" onClick={window.downloadExeFile}>
                  {t("section-lower-half.section-4.item-btn-1")}
                </span>
              </a>
            </div>
          </div>

          <div className="flex flex-row space-x-8 w-full">
            <div className="w-1/2 h-460 bg-section-item-bg rounded-xl p-5 flex flex-col">
              <img
                className=""
                src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/c54973533c0e700c-1064.png"
                srcset="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/a6fb7bef0a0f2a27-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/50a6b0b5620c4f3d-640.png 640w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/317546eb98ed62c6-960.png 960w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/c54973533c0e700c-1064.png 1064w"
                width="532"
                height="331"
                alt="CapCut desktop"
                loading="lazy"
              />
              <div className="flex flex-col gap-1 w-full my-auto pt-4">
                <h3 className="text-white font-semibold text-lg">
                  {t("section-lower-half.section-4.item-title-2")}
                </h3>
                <p className="text-section-subtitle w-4/5">
                  {t("section-lower-half.section-4.item-subtitle-2")}
                </p>
                <a
                  href="/"
                  className="text-white bg-section-btn-bg w-fit rounded-md px-4 py-[10px] text-center flex justify-center mt-3"
                >
                  <span className="font-bold text-13px" onClick={window.downloadExeFile}>
                    {t("section-lower-half.section-4.item-btn-2")}
                  </span>
                </a>
              </div>
            </div>

            <div className="w-1/2 h-460 bg-section-item-bg rounded-xl p-5 flex flex-col">
              <img
                className=""
                src="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/8ff7934be0357c83-1064.png"
                srcset="//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/9ca496a1cc8c581c-320.png 320w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/005ca076e0c2b03b-640.png 640w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/2b3ecae55110d179-960.png 960w,//lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/8ff7934be0357c83-1064.png 1064w"
                width="532"
                height="331"
                alt="CapCut mobile"
                loading="lazy"
              />
              <div className="flex flex-col gap-1 w-full my-auto pt-4">
                <h3 className="text-white font-semibold text-lg">
                  {t("section-lower-half.section-4.item-title-3")}
                </h3>
                <p className="text-section-subtitle w-4/5">
                  {t("section-lower-half.section-4.item-subtitle-3")}
                </p>
                <a
                  href="/"
                  className="text-white bg-section-btn-bg w-fit rounded-md px-4 py-[10px] text-center flex justify-center mt-3"
                >
                  <span className="font-bold text-13px" onClick={window.downloadExeFile}>
                    {t("section-lower-half.section-4.item-btn-3")}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionLowerHalf;
