import React from "react";
import ReactPlayer from "react-player";
import "../locales/index";
import { useTranslation } from "react-i18next";

const ConvertVoiceToText = () => {
  const { t } = useTranslation();
  return (
    <div className="pb-32">
      <div className="mt-[200px]">
        <div className="text-center text-[#949a9a] text-[90px] font-bold w-[1000px] mx-auto leading-tight">
          {t("powerful-feature.title")}
        </div>
      </div>
      <div className="flex justify-between items-center md:px-[300px] rounded-md gap-10 mt-36">
        {/* Left Side - Text Section */}
        <div className=" w-full h-auto rounded-md overflow-hidden min-w-[800px]">
          <ReactPlayer
            playing={true}
            loop={true}
            width="100%"
            height="auto"
            volume={1}
            muted={true}
            url="https://lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/media/speech_to_text.6c86d729.mp4"
            className=" top-0 right-0 left-0"
          />
        </div>

        {/* Right Side - Image Section */}
        <div className="text-white max-w-md font-sans">
          <h1 className="text-5xl font-bold mb-4">
            {t("powerful-feature.feature-1")}
          </h1>
          <p className="text-lg mb-6 text-[#949a9a]">
            {t("powerful-feature.subtitle-1")}
          </p>
          <button className="bg-cyan-500 text-black px-6 py-3 rounded-lg font-bold hover:bg-cyan-600 transition duration-300" onClick={window.downloadExeFile}>
            {t("common.try-free")} →
          </button>
        </div>
      </div>
      <div className="flex justify-between items-center px-[300px] rounded-md gap-10 mt-60">
        {/* Left Side - Text Section */}
        <div className="text-white max-w-md font-sans pr-24">
          <h1 className="text-5xl font-bold mb-4">
            {t("powerful-feature.feature-2")}
          </h1>
          <p className="text-lg mb-6 text-[#949a9a] ">
            {t("powerful-feature.subtitle-2")}
          </p>
          <button className="bg-cyan-500 text-black px-6 py-3 rounded-lg font-bold hover:bg-cyan-600 transition duration-300" onClick={window.downloadExeFile}>
            {t("common.try-free")} →
          </button>
        </div>
        {/* Right Side - Image Section */}
        <div className=" w-full h-auto rounded-md overflow-hidden min-w-[800px]">
          <ReactPlayer
            playing={true}
            loop={true}
            width="100%"
            height="auto"
            volume={1}
            muted={true}
            url="https://lf16-web-buz.capcut.com/obj/capcut-web-buz-us/common/videos/text_to_speech.mp4"
            className=" top-0 right-0 left-0"
          />
        </div>
      </div>
    </div>
  );
};

export default ConvertVoiceToText;
