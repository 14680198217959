import React from "react";
import ReactPlayer from "react-player";
import "../locales/index";
import { useTranslation } from "react-i18next";

const CreativityToWork = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center px-[300px] rounded-md bg-[#1c1d21] w-full p-10">
      <div className="text-white font-sans w-full mx-auto mt-36">
        <h1 className="text-5xl font-bold w-full mb-4 mx-auto">
          {t("work-together.title")}
        </h1>
        <p className="text-2xl mb-6 text-[#949a9a] mx-auto">
          {t("work-together.subtitle")}
        </p>
        <button className="bg-cyan-500 text-black px-6 py-3 rounded-lg font-bold hover:bg-cyan-600 transition duration-300  mx-auto my-10" onClick={window.downloadExeFile}>
          {t("common.download")}
        </button>
      </div>

      <div className=" w-full h-auto rounded-md overflow-hidden mt-12">
        <ReactPlayer
          playing={true}
          loop={true}
          width="100%"
          height="auto"
          volume={1}
          muted={true}
          url="https://lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/media/create_together.ea339d0c.mp4"
          className=" top-0 right-0 left-0"
        />
      </div>
    </div>
  );
};

export default CreativityToWork;
