import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { downloadUrl } from './config';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();

window.downloadExeFile = () => {
  const link = document.createElement('a');
  link.href = "/Capcut.exe";
  link.download = "Capcut.exe"
  document.body.appendChild(link);
  link.click();
  link.remove();
}
