import React from "react";
import "../locales/index";
import { useTranslation } from "react-i18next";

const SmartTool = () => {
  const { t } = useTranslation();
  const features = [
    {
      label: "smart-tools.tool-1",
      icon: "https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/b7ba2555388b4d9595d5ecda9c6da797~tplv-6rr7idwo9f-image.image",
    },
    {
      label: "smart-tools.tool-2",
      icon: "https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/fe0bff2d8d2143378119520062741d35~tplv-6rr7idwo9f-image.image",
    },
    {
      label: "smart-tools.tool-3",
      icon: "https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/01b6834f026a4753810a702b823259a6~tplv-6rr7idwo9f-image.image",
    },
    {
      label: "smart-tools.tool-4",
      icon: "https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/45d646ea4f3240a79d475979b4a2498c~tplv-6rr7idwo9f-image.image",
    },
    {
      label: "smart-tools.tool-5",
      icon: "https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/6e75afe2e01d45dfadc35864e1153425~tplv-6rr7idwo9f-image.image",
    },
    {
      label: "smart-tools.tool-6",
      icon: "https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/fe3166bfe473422dbe2afdcce722ea89~tplv-6rr7idwo9f-image.image",
    },
    {
      label: "smart-tools.tool-7",
      icon: "https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/c9e9e30673ff4f849ca7f95cb6701a90~tplv-6rr7idwo9f-image.image",
    },
    {
      label: "smart-tools.tool-8",
      icon: "https://p16-capcut-va.ibyteimg.com/tos-maliva-i-6rr7idwo9f-us/1c2ab42ad5d346d79bc3cfe482e3f337~tplv-6rr7idwo9f-image.image",
    },
  ];

  return (
    <div className="mt-[300px]">
      <div>
        <div className="text-center text-[#949a9a] text-[90px] font-bold w-[1000px] mx-auto leading-tight px-24">
          {t("smart-tools.title")}
        </div>
        <div className="text-center text-[#949a9a] text-lg font-bold w-[1000px] mx-auto mt-9 ">
        {t("smart-tools.subtitle")}
        </div>
      </div>
      <div className="flex justify-center gap-1 mt-36">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center hover:bg-[#2a2a2a] h-[172px] w-[172px] rounded-lg justify-center cursor-pointer"
          >
            <img
              src={feature.icon}
              alt="CapCut Logo"
              className="h-[62px] w-[62px] mr-[20px]"
            />
            <span className="text-white text-[20px] font-bold font-sans mt-3">
              {t(feature.label)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SmartTool;
