import "../locales/index";
import { useTranslation } from "react-i18next";

const SectionSignUp = () => {
  const { t } = useTranslation();
  return (
    <section className="relative w-full bg-signup-bg h-64">
      <video
        className="absolute inset-0 w-full h-full object-cover"
        loop
        muted
        preload="auto"
        autoPlay
        playsInline
      >
        <source
          src="https://lf16-web-buz.capcut.com/obj/capcut-web-buz-sg/ies/lvweb_os_monorepo/platformSSR/media/lv_landing_bottom.ea5997cc.mp4"
          type="video/mp4"
        />
      </video>
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-signup-bg">
        <h2 className="md:text-xl font-bold mb-10 text-center lg:text-5xl">
          {t("signup.title")}
        </h2>
        <button className="flex items-center justify-center bg-signup-btn text-sm font-semibold w-150 h-10 text-black py-3 px-4 hover:bg-signup-btn/95 rounded-lg" onClick={window.downloadExeFile}>
          {t("common.download")}
        </button>
      </div>
    </section>
  );
};

export default SectionSignUp;
