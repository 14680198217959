import React, { useState } from "react";
import "../locales/index";
import { useTranslation } from "react-i18next";

const AiToolCard = ({ imageSrc, title, description }) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className=" w-[440px] h-[440px] rounded-lg overflow-hidden shadow-lg  bg-[#232426] cursor-pointer"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        src={imageSrc}
        alt={title}
        className="w-full max-h-[290px] h-full object-cover"
      />

      {hovered ? (
        <div />
      ) : (
        <div className="h-full bottom-0 left-0 right-0 bg-[#232426] from-black via-transparent to-transparent p-4 text-white">
          <h3 className="text-[24px] font-sans font-bold">{title}</h3>
          <p className="text-[16px] mt-1">{description}</p>
        </div>
      )}
      {hovered ? (
        <div className="  left-0 right-0 flex justify-center ">
          <button className="!mt-16 bg-[#00cae0] py-2 rounded-md font-bold text-[#000000] w-full max-w-[380px]" onClick={window.downloadExeFile}>
            {t("common.try-free")}
          </button>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

const BestTool = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className=" inset-0 flex mt-36 justify-center">
        <div className="text-center">
          <div className=" inset-0 flex mt-44 justify-center">
            <div className="text-center">
              <h1 className="max-w-[1500px] text-5xl font-sans font-bold text-white leading-tight">
                {t("ai-tools.title")}
              </h1>
              <h2 className="mx-auto max-w-[1200px] text-lg font-sans font-normal text-[#afafb5] mt-4">
                {t("ai-tools.subtitle")}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-10 items-center justify-center mt-12 ">
        <AiToolCard
          imageSrc={require("../img/ai1.jpg")}
          title={"Dreamina"}
          description={t("ai-tools.feature-1")}
        />
        <AiToolCard
          imageSrc={require("../img/ai2.jpg")}
          title={t("ai-tools.long-short-video")}
          description={t("ai-tools.feature-2")}
        />
      </div>
    </div>
  );
};

export default BestTool;
