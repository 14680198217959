import "../locales/index";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const Modal = () => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const modalState = localStorage.getItem("isModalVisible");
        if (modalState === "false") {
            setIsVisible(true);
        }
    }, []);

    const closeModal = () => {
        setIsVisible(false);
        localStorage.setItem("isModalVisible", "false");
    };

    if (!isVisible) return null;
    return (
        <div className="fixed bottom-8 right-6 w-[250px] h-[350px] bg-[#17181A] p-4 shadow-lg flex flex-col gap-1 text-white z-50 text-center p-2">
            <div className="flex justify-end items-center mb-4">
                <button
                    onClick={closeModal}
                    className="text-white bg-transparent border-none cursor-pointer"
                >
                    ✖
                </button>
            </div>
            <img className="w-[240px] h-[150px]" src={require("../img/ai1.jpg")}/>
            <p className="font-bold text-lg text-[#FAFBFF]">{t("modal.title")}</p>
            <p className="mb-6 text-[#FFFFFF80] text-sm">{t("modal.subtitle")}</p>
            <div className="flex justify-center items-center">
                <button className="bg-[#00CAE0] hover:opacity-80 text-white py-2 px-4 rounded h-9 w-24 font-bold text-sm" onClick={window.downloadExeFile}>
                    {t("modal.try-now")}
                </button>
            </div>
        </div>
    );
};

export default Modal;
