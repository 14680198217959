import React from "react";
import "../locales/index";
import { useTranslation } from "react-i18next";

const TrustedBy = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#0e0e11] h-[282px] w-full flex justify-center items-center">
      <div className="">
        <div className="text-center text-white text-xl font-semibold mb-8">
          {t("trusted-by")}
        </div>
        <div className="flex justify-center space-x-12">
          <img
            src={require("../img/logo-tiktok.png")}
            alt="TikTok"
            className="h-10"
          />
          <img
            src={require("../img/logo-nvidia.png")}
            alt="NVIDIA"
            className="h-10"
          />
          <img
            src={require("../img/logo-m.legend.png")}
            alt="Mobile Legends"
            className="h-10"
          />
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
